import store from "@/vuex/store";
import {prettyLog} from "@/utils/prettyLog";
import {computed} from "vue";

/**
 * 为传递数组数据解析请求参数
 * @param list
 * @returns {*}
 */
export const encodeList = list =>{
    return list.join('&ids=')
}

export const formatYear = (dateString)=> {
    const date = new Date(dateString);
    return date.getFullYear()
}
export const formatTime = dateString => {
    const dateCN = new Date(dateString)
    // 格式化日期
    const formatterCN = new Intl.DateTimeFormat('zh-CN', {
        timeZone: 'Asia/Shanghai',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })
    return formatterCN.format(dateCN)
}


const getClassNameById = (id)=> {
    const classList = computed(()=>store.getters.classes)
    const result =  classList.value.find(clazz=>clazz.id===id)
    return result?result.className:null
}

const getClassIdByName = (value)=> {
    const classList = computed(()=>store.getters.classes);
    const result = classList.value.find(clazz=>clazz.className===value)
    return result?result.id:null
}

const getJobIdByName = (value)=> {
    const classList = computed(()=>store.getters.jobs);
    const result = classList.value.find(job=>job.name===value)
    return result?result.id:null
}

const getJobNameById = (id)=> {
    const classList = computed(()=>store.getters.jobs);
    const result = classList.value.find(job=>job.id===id)
    return result?result.name:null
}

const getDepIdByName = (value)=> {
    const classList = computed(()=>store.getters.departments);
    const result = classList.value.find(job=>job.label===value)
    return result?result.value:null
}
const getDepNameById = (id)=> {
    const classList = computed(()=>store.getters.departments);
    const result = classList.value.find(job=>job.value===id)
    return result?result.label:null
}

const clearObject = (object)=>{
    for (let key in object){
        delete object[key];
    }
}

/**
 * 将微信模板消息中的内容解析为Map。
 *
 * @param {string} content - 模板消息内容
 * @return {Object} - 解析后的键值对对象
 */
function parseTemplateContent(content) {
    console.log(content)
    // 正则表达式用于匹配键名和描述
    const pattern = /(.*?)：\{\{(\w+)\.DATA\}\}/g;
    let match;
    const resultMap = {};


    resultMap.first = "首要内容";

    while ((match = pattern.exec(content)) !== null) {
        const description = match[1].trim();
        const key = match[2];
        resultMap[key] = description;
        console.log("执行一次",key)
    }

    resultMap.remark = "备注内容";

    return resultMap;
}

/**
 * 判断对象是否为空
 * @param obj
 * @return {boolean}
 */
function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
}

export const tools = {
    getClassNameById,
    getClassIdByName,
    getJobIdByName,
    getJobNameById,
    getDepIdByName,
    getDepNameById,
    clearObject,
    isEmptyObject,
    parseTemplateContent
}