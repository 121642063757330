import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createCommentVNode as _createCommentVNode, vShow as _vShow, createTextVNode as _createTextVNode, Transition as _Transition, createBlock as _createBlock, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
import _imports_0 from '@/assets/logo_ch_white.png';
import _imports_1 from '@/assets/beian_logo.png';
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "card_group"
};
const _hoisted_3 = {
  class: "card1"
};
const _hoisted_4 = {
  class: "logo"
};
const _hoisted_5 = {
  class: "card"
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = {
  class: "from"
};
const _hoisted_9 = {
  style: {
    "margin-top": "20px",
    "width": "100%"
  }
};
const _hoisted_10 = {
  key: 0
};
const _hoisted_11 = {
  style: {
    "margin": "0 auto"
  }
};
import { onBeforeMount, onMounted, onUnmounted, reactive, ref } from 'vue';
import { User, Lock } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';
import { HttpStatusCode } from "axios";
import { onBeforeRouteUpdate, useRouter } from "vue-router";
import store from "@/vuex/store";
import { wechat } from "@/api/wechat";
import WxLogin from "vue-wxlogin";
import { bindOpenId, login } from "@/api/user";
import { hasPhoneNumber } from "@/api/employee";
import { prettyLog } from "@/utils/prettyLog";
import { setToken, setUserId } from "@/utils/auth";
const wechatQrCodeStyle = "data:text/css;base64," + "LmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCg==";
export default {
  __name: 'LoginPage',
  setup(__props) {
    onBeforeMount(() => {
      enterEvent();
      prettyLog().info("123");
    });
    const enterEvent = () => {
      document.onkeydown = e => {
        if (e.key === "Enter" && e.target.baseURI.match(/login/)) {
          // match(此处应填写文件在浏览器中的地址，如 '/home/index')，不写的话，其他页面也会有调用回车按下的方法
          submitForm(ruleFormRef.value);
        }
      };
    };
    onUnmounted(() => {
      // 取消事件绑定
      document.onkeydown = null;
    });
    const isVisibleBind = ref(false);
    const isClickCode = ref(false);
    const bindButtonType = ref('primary');
    const ruleFormRef = ref(null);
    const ruleFormRef_bind = ref(null);
    const router = useRouter();
    let loading = ref(false);
    const isWechatLogin = ref(true);
    const ruleForm = reactive({
      user: '',
      pass: ''
    });
    const form_bind = reactive({
      phoneNumber: null,
      code: null
    });
    const _data = reactive({
      sensitive: {
        appid: 'wx1094f9822a32d5d4',
        scope: 'snsapi_login'
      },
      redirect_url: "https%3A%2F%2Fhxy.zzch.cn%2Fcallback"
    });

    // 检查用户名验证器
    const checkUser = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('账号不能为空'));
      }
      setTimeout(() => {
        if (value.size < 6) {
          callback(new Error('账号不得少于6个字符'));
        } else {
          callback();
        }
      }, 1000);
    };

    // 检查密码验证器
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入你的密码！'));
      } else {
        if (value < 6) {
          callback(new Error('密码不得少于6位数.'));
        }
      }
      callback();
    };

    // 绑定验证器
    const rules = reactive({
      user: [{
        validator: checkUser,
        trigger: 'blur'
      }],
      pass: [{
        validator: validatePass,
        trigger: 'blur'
      }]
    });

    // 绑定验证器
    const rules_bind = reactive({
      phoneNumber: [{
        required: true,
        message: '请输入手机号码',
        trigger: 'blur'
      }, {
        pattern: /^1[3456789]\d{9}$/,
        message: '请输入正确的手机号码',
        trigger: ['blur', 'change']
      }],
      code: [{
        required: true,
        message: '验证码不能为空',
        trigger: 'blur'
      }, {
        min: 6,
        max: 6,
        message: '必须为6位数的数字',
        trigger: ['blur', 'change']
      }]
    });

    // 提交事件
    const submitForm = ruleFormRef => {
      // console.log(process.env.NODE_ENV) 判断当前是否是生产环境

      if (!ruleFormRef || isWechatLogin.value) return;
      loading.value = true;
      ruleFormRef.validate(valid => {
        // 注意：此时使用的是 ruleFormRef.value，而仅写 ruleFormRef 是拿不到值且会报错的;
        if (valid) {
          // 注意：只有当所有的规则都满足后，此时的 valid 的值才为 true，才能执行下面的值;

          const userAccount = {
            phoneNumber: ruleForm.user,
            password: ruleForm.pass
          };
          store.dispatch("user/login", userAccount).then(res => {
            if (res.status) {
              if (res.status === HttpStatusCode.Ok && res.data.code === HttpStatusCode.Ok) {
                setToken(res.data.data.token);
                setUserId(res.data.data.userId);
                ElMessage({
                  message: '登录成功！',
                  type: 'success',
                  center: true
                });
                router.push('admin');
              } else {
                ElMessage({
                  message: '账号或密码错误！请重新输入。',
                  type: 'error',
                  center: true
                });
              }
              loading.value = false;
              return true;
            }
            ElMessage({
              message: '登录失败！',
              type: 'error',
              center: true
            });
            loading.value = false;
            return false;
          }).catch(error => {
            console.log(error);
            loading.value = false;
            return false;
          });
        }
      });
    };

    // 倒计时完成
    const onCountDownFinish = () => {
      bindButtonType.value = 'primary';
      isClickCode.value = false;
    };

    // 点击验证码事件
    const onClickToGetCode = () => {
      isClickCode.value = true;
      bindButtonType.value = '';
    };

    // 对话框关闭事件
    const onDialogClose = () => {
      if (ruleFormRef_bind.value === null) return;
      ruleFormRef_bind.value.resetFields();
      ElMessage.info('取消绑定用户');
    };

    // 点击绑定事件
    const onClickBind = formEl => {
      console.log("formEl", formEl);
      if (!formEl) return;
      formEl.validate(valid => {
        if (valid) {
          // 验证手机号码是否存在
          bindOpenId({
            openid: props.code,
            phoneNumber: form_bind.phoneNumber
          }).then(res => {
            if (res.data.code < 400) {
              ElMessage.success("绑定成功，正在登录...");
              // 尝试登录
              wechat.login(props.code).then(res => {
                ElMessage.success('登录成功!');
                router.push('admin');
              }).catch(error => {
                console.log("登录出错", error);
                ElMessage.error('登录出错!');
              });
            } else {
              ElMessage.error(res.data.msg);
              isVisibleBind.value = false;
            }
          }).catch(error => {
            console.log(error);
          });
        }
      });
    };

    // 支付宝登录
    const zfbLogin = () => {
      ElMessage('暂未开放，正在开发中！');
    };
    return (_ctx, _cache) => {
      const _component_el_radio_button = _resolveComponent("el-radio-button");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_countdown = _resolveComponent("el-countdown");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_has = _resolveDirective("has");
      return _openBlock(), _createElementBlock(_Fragment, null, [_cache[18] || (_cache[18] = _createElementVNode("div", {
        class: "bg"
      }, null, -1)), _createElementVNode("div", _hoisted_1, [_createVNode(_Transition, {
        name: "el-zoom-in-center",
        appear: ""
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, _cache[9] || (_cache[9] = [_createElementVNode("img", {
          alt: "logo of ChangHong",
          src: _imports_0
        }, null, -1)]))), [[_directive_has, true]]), _cache[10] || (_cache[10] = _createElementVNode("h1", null, "鸿校园信息管理", -1)), _cache[11] || (_cache[11] = _createElementVNode("h4", null, "校园集中信息管理 更方便 更高效", -1))]), _createElementVNode("div", _hoisted_5, [_cache[14] || (_cache[14] = _createElementVNode("h2", null, "欢迎回来", -1)), _createVNode(_component_el_radio_group, {
          style: {
            "margin-top": "-20px",
            "margin-bottom": "20px"
          },
          modelValue: isWechatLogin.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => isWechatLogin.value = $event),
          size: "large"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_button, {
            label: "微信登录",
            value: true
          }), _createVNode(_component_el_radio_button, {
            label: "账号登录",
            value: false
          })]),
          _: 1
        }, 8, ["modelValue"]), _withDirectives(_createElementVNode("div", null, [_data.sensitive != null ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_unref(WxLogin), {
          appid: _data.sensitive.appid,
          scope: _data.sensitive.scope,
          redirect_uri: _data.redirect_url,
          href: wechatQrCodeStyle,
          state: "pc"
        }, null, 8, ["appid", "scope", "redirect_uri"])])) : (_openBlock(), _createElementBlock("div", _hoisted_7, "二维码加载中..."))], 512), [[_vShow, isWechatLogin.value]]), _withDirectives(_createElementVNode("div", null, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_el_form, {
          ref_key: "ruleFormRef",
          ref: ruleFormRef,
          model: ruleForm,
          "status-icon": "",
          rules: rules
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            prop: "user"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              class: "ipt",
              "prefix-icon": _unref(User),
              size: "large",
              type: "text",
              modelValue: ruleForm.user,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ruleForm.user = $event),
              placeholder: "请输入账号/手机号码"
            }, null, 8, ["prefix-icon", "modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            prop: "pass"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              class: "ipt",
              "prefix-icon": _unref(Lock),
              size: "large",
              type: "password",
              modelValue: ruleForm.pass,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ruleForm.pass = $event),
              clearable: "",
              "show-password": "",
              placeholder: "请输入密码"
            }, null, 8, ["prefix-icon", "modelValue"])]),
            _: 1
          }), _cache[13] || (_cache[13] = _createElementVNode("a", {
            style: {
              "color": "#888",
              "font-size": "0.9375rem"
            }
          }, [_createTextVNode("还没有账号？"), _createElementVNode("span", {
            style: {
              "color": "#3498db"
            }
          }, "点我注册")], -1)), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createVNode(_component_el_button, {
              loading: _unref(loading),
              type: "primary",
              onClick: _cache[3] || (_cache[3] = $event => submitForm(ruleFormRef.value))
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("登录")])),
              _: 1
            }, 8, ["loading"])])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])])], 512), [[_vShow, !isWechatLogin.value]])])])]),
        _: 1
      })]), _createVNode(_component_el_dialog, {
        modelValue: isVisibleBind.value,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => isVisibleBind.value = $event),
        title: "绑定手机",
        width: "420",
        "align-center": "",
        onClose: onDialogClose
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "hide-required-asterisk": "",
          ref_key: "ruleFormRef_bind",
          ref: ruleFormRef_bind,
          model: form_bind,
          rules: rules_bind,
          "label-width": "80px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            prop: "phoneNumber",
            label: "手机号码"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              size: "large",
              modelValue: form_bind.phoneNumber,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => form_bind.phoneNumber = $event),
              style: {
                "max-width": "300px"
              },
              placeholder: "请输入手机号码"
            }, {
              prepend: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode("+86")])),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            prop: "code",
            label: "验证码"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              clearable: "",
              size: "large",
              modelValue: form_bind.code,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => form_bind.code = $event),
              style: {
                "max-width": "170px"
              },
              placeholder: "请输入验证码"
            }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
              type: bindButtonType.value,
              disabled: isClickCode.value,
              onClick: onClickToGetCode,
              style: {
                "width": "108px",
                "margin-left": "20px"
              },
              size: "large"
            }, {
              default: _withCtx(() => [!isClickCode.value ? (_openBlock(), _createElementBlock("div", _hoisted_10, "获取验证码")) : _createCommentVNode("", true), isClickCode.value ? (_openBlock(), _createBlock(_component_el_countdown, {
                key: 1,
                format: "s 秒",
                "value-style": {
                  fontSize: '15px',
                  color: 'gray'
                },
                value: Date.now() + 1000 * 30,
                onFinish: onCountDownFinish
              }, null, 8, ["value"])) : _createCommentVNode("", true)]),
              _: 1
            }, 8, ["type", "disabled"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            "label-width": "0px"
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_11, [_createVNode(_component_el_button, {
              onClick: _cache[6] || (_cache[6] = $event => isVisibleBind.value = false)
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [_createTextVNode("取消")])),
              _: 1
            }), _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[7] || (_cache[7] = $event => onClickBind(ruleFormRef_bind.value))
            }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [_createTextVNode("绑定")])),
              _: 1
            })])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue"]), _cache[19] || (_cache[19] = _createStaticVNode("<div class=\"beian\" data-v-66da8f7d><a href=\"https://beian.miit.gov.cn/\" target=\"_blank\" style=\"text-decoration:none;\" rel=\"noopener\" data-v-66da8f7d><img src=\"" + _imports_1 + "\" style=\"width:16px;height:16px;vertical-align:middle;\" data-v-66da8f7d><span style=\"color:#939393;font-size:12px;vertical-align:middle;\" data-v-66da8f7d>湘ICP备18010092号-1</span></a><a href=\"https://beian.miit.gov.cn/\" target=\"_blank\" style=\"text-decoration:none;\" rel=\"noopener\" data-v-66da8f7d><span style=\"color:#939393;font-size:12px;vertical-align:middle;\" data-v-66da8f7d> 版权所有 © 株洲长鸿实验学校</span></a></div>", 1))], 64);
    };
  }
};